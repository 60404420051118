<ng-container *transloco="let t; read: 'import-cbl-modal'">

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">CBL Import</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body scrollable-modal">
    <div class="row g-0" style="min-width: 135px;">
      <app-step-tracker [steps]="steps" [currentStep]="currentStepIndex"></app-step-tracker>
    </div>

    <!-- This is going to need to have a fixed height with a scrollbar-->
    <div>
      <div class="row g-0" *ngIf="currentStepIndex === Step.Import">
        <p>{{t('import-description')}}</p>
        <form [formGroup]="uploadForm" enctype="multipart/form-data">
          <file-upload formControlName="files"></file-upload>
        </form>
      </div>

      <ng-container *ngIf="currentStepIndex === Step.Validate">
        <p>{{t('validate-description')}}</p>
        <div class="row g-0">

          <div ngbAccordion #accordion="ngbAccordion">
            @for(fileToProcess of filesToProcess; track fileToProcess.fileName) {
              <div ngbAccordionItem *ngIf="fileToProcess.validateSummary as summary">
                <h5 ngbAccordionHeader>
                  <button ngbAccordionButton>
                    <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                  </button>
                </h5>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    @if(summary.results.length > 0) {
                      <h5>{{t('validate-warning')}}</h5>
                      <ol class="list-group list-group-numbered list-group-flush" >
                        <li class="list-group-item no-hover" *ngFor="let result of summary.results"
                            [innerHTML]="result | cblConflictReason | safeHtml">
                        </li>
                      </ol>
                    } @else {
                      <div class="justify-content-center col">
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0">
                            <i class="fa-solid fa-circle-check" style="font-size: 24px" aria-hidden="true"></i>
                          </div>
                          <div class="flex-grow-1 ms-3">
                            {{t('validate-no-issue')}}
                          </div>
                        </div>
                        {{t('validate-no-issue-description')}}
                      </div>
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStepIndex === Step.DryRun">
        <div class="row g-0">
          <p>{{t('dry-run-description')}}</p>

          <div ngbAccordion #a="ngbAccordion">
            @for(fileToProcess of filesToProcess; track fileToProcess.fileName) {
              <div ngbAccordionItem *ngIf="fileToProcess.dryRunSummary as summary">
                <h5 ngbAccordionHeader>
                  <button ngbAccordionButton>
                    <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                  </button>
                </h5>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-container [ngTemplateOutlet]="resultsList" [ngTemplateOutletContext]="{ summary: summary }"></ng-container>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentStepIndex === Step.Finalize">
        <div class="row g-0">
          <div ngbAccordion #a="ngbAccordion">
            @for(fileToProcess of filesToProcess; track fileToProcess.fileName) {
              <div ngbAccordionItem *ngIf="fileToProcess.finalizeSummary as summary">
                <h5 ngbAccordionHeader>
                  <button ngbAccordionButton>
                    <ng-container [ngTemplateOutlet]="heading" [ngTemplateOutletContext]="{ summary: summary, filename: fileToProcess.fileName }"></ng-container>
                  </button>
                </h5>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-container [ngTemplateOutlet]="resultsList" [ngTemplateOutletContext]="{ summary: summary }"></ng-container>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </ng-container>
    </div>

    <ng-template #resultsList let-summary="summary">
      <ul class="list-group list-group-flush">
        @for(result of summary.results; track result.order) {
          <li class="list-group-item no-hover"
              innerHTML="{{result.order + 1}}. {{result | cblConflictReason | safeHtml}}"></li>
        }
      </ul>
    </ng-template>

    <ng-template #heading let-filename="filename" let-summary="summary">
        <ng-container *ngIf="summary.success | cblImportResult as success">
          <ng-container [ngSwitch]="summary.success">
            <span *ngSwitchCase="CblImportResult.Success" class="badge bg-primary me-1">{{success}}</span>
            <span *ngSwitchCase="CblImportResult.Fail" class="badge bg-danger me-1">{{success}}</span>
            <span *ngSwitchCase="CblImportResult.Partial" class="badge bg-warning me-1">{{success}}</span>
          </ng-container>
        </ng-container>
        <span>{{filename}}<span *ngIf="summary.cblName">: ({{summary.cblName}})</span></span>
    </ng-template>


  </div>
  <div class="modal-footer">
    <a class="btn btn-icon" href="https://wiki.kavitareader.com/en/guides/get-started-using-your-library/reading-lists#creating-a-reading-list-via-cbl" target="_blank" rel="noopener noreferrer">Help</a>
    <button type="button" class="btn btn-secondary" (click)="close()">{{t('close')}}</button>
    <button type="button" class="btn btn-primary" (click)="prevStep()" [disabled]="!canMoveToPrevStep()">{{t('prev')}}</button>
    <button type="button" class="btn btn-primary" (click)="nextStep()" [disabled]="!canMoveToNextStep()">{{t(NextButtonLabel)}}</button>
  </div>



</ng-container>
